require('magnific-popup');

var App = {
    popupPars: function(popup, options) {
        var defaults =  {
            mainClass: 'mfp-fade',
            removalDelay: 500,
            closeBtnInside: false,
            fixedContentPos: true,
        };

        return $.extend({}, defaults, options);
    },
    init: function(className) {
        var popups = $(className);
        popups.each(function(){

            var popup = $(this);
            popup.magnificPopup(App.popupPars(popup));

        });
    }
}

module.exports = App;

var App = {
    init: function() {
        try {
            $('.win_lose_field').hide();

            var showErrorMessage = 0;
            $('.pin_code_field').on('blur', 'input, textarea, select', function(e) {
                 var pinLength = $(this).parents('.contest-form').data('pin-length');
                 var inputLength = $(this).val().length;

                if (inputLength !== pinLength && showErrorMessage === 0 ) {
                    $('.pin_code_field').append('<label class="gfield_label pin_error">Please check the number of characters in your PIN code</label>');
                    showErrorMessage = 1;

                } else if (inputLength === pinLength ) {
                    $( ".pin_error" ).remove();
                    showErrorMessage = 1;
                }

            });



            // $(document).bind('gform_post_render', function (event, formId, current_page) {
            //     console.log(current_page);
            // });

            // Gravity Form's AJAX submission removes the listeners in the form when submitted
            // Therefore we bind a listener to the outerwrapper and delegate

            //
            // $('form, .gform_wrapper').on('blur', 'input, textarea, select', function(e) {
            //     $(this).closest('.form__group, .gfield').removeClass('focus');
            // });




        }
        catch(e) {}


    }
}

module.exports = App;

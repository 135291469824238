/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 *
 * App and Module call examples:
 * Manager.ScrollController = new App.ScrollController();
 * Manager.ScrollController.setModule(new MyModules.Scroll_FadeInBlocks());
 * ======================================================================== */

window.$ = window.jQuery = require('jquery');
 var myApp = myApp || {};

// Define Methods/Functions outside the router for reusability

// var scrollController        = require('./apps/scrollController.js'),
    // sliderController        = require('./apps/sliderController.js'),
    // mobileNavController     = require('./apps/mobileNavController.js'),
    // mapsController          = require('./apps/mapsController.js'),
    // scrollDownController    = require('./apps/scrollDownController.js');
    popupController         = require('./apps/popupController.js');

    // js-fade-block module
    // scrollController.setModule(fadeInBlocksController);

var imageLoadedController   = require('./apps/imageLoadedController.js'),
    formController          = require('./apps/formController.js');


// var Contest = require('./modules/__contests.js');

// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.
var Router = {
    // All pages
    common: {

        init: function() {

            // Javascript to be fired on every page
            // These can be deleted or refactored to the page where it is used

            // Initialize common controllers
            // scrollController.finalize('.js-slider');
            // sliderController.init('.js-slider');
            // mobileNavController.init('.js-navigation-toggle');
            // mapsController.init('.js-map');
            formController.init();
            imageLoadedController.init('.js-loaded');
            // scrollDownController.init('#scroll-to-content', '#page-masthead', true);
        },

        // Finalize will get fired after all js in common.init and page specific js
        finalize: function() {
            // scrollController.finalize();
        }

    },

    single_contests: {
        init: function() {
            popupController.init('.js-popup');




        }
    },
};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {

    fire: function(func, funcname, args) {
        var namespace = Router;
        funcname = (funcname === undefined) ? 'init' : funcname;
        if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
            namespace[func][funcname](args);
        }
    },
    loadEvents: function() {
        UTIL.fire('common');

        $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
            UTIL.fire(classnm);
        });

        UTIL.fire('common', 'finalize');
    }

};

$(document).ready(UTIL.loadEvents);
